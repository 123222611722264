// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as labs from 'vuetify/labs/components'
import { ja } from 'vuetify/locale'



const myCustomTheme: ThemeDefinition = {
    colors: {
        primary: '#3F5689',
        secondary: '#C9DBEA',
        accent: '#ECF4F8'
    }
  }

const vuetify = createVuetify({
    components: {
      ...labs,
    },
    directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'myCustomTheme',
      themes: {
          myCustomTheme,
      }
    },
    locale: {
      locale: 'ja',
      messages: { ja },
    }
});

export default vuetify
