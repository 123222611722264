import { createRouter, createWebHistory, useRouter } from 'vue-router'

const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(),
  routes: [
    {
      path: '/data-service/login',
      name: 'login',
      component: () => import('@/components/pages/Login/LoginPage.vue')
    },
    {
      path: '/data-service/admin/login',
      name: 'admin-login',
      component: () => import('@/components/pages/Login/AdminLoginPage.vue')
    },
    {
      path: '/data-service/forgot-password/:id',
      name: 'reissue-password',
      component: () => import('@/components/pages/Login/ReissuePasswordPage.vue')
    },
    // 後処理データ入力画面（ホーム画面）
    {
      path: '/data-service/forgot-password',
      name: 'forgot-password',
      component: () => import('@/components/pages/Login/ForgotPasswordFormPage.vue')
    },
    {
      path: '/data-service/inputData',
      name: 'inputData',
      component: () => import('@/components/pages/InputData/InputData.vue'),
      beforeEnter: (to, from, next) => {
        authentication()
        next();
      }
    },
    // ダウンロード履歴画面
    {
      path: '/data-service/download-history',
      name: 'downloadHistory',
      component: () => import('@/components/pages/DownloadDataHistory/DownloadDataHistory.vue'),
      beforeEnter: (to, from, next) => {
        authentication()
        next();
      }
    },
    {
      path: '/data-service/management',
      name: 'management',
      component: () => import('@/components/pages/Management/Management.vue'),
      beforeEnter: (to, from, next) => {
        authmanage()
        next();
      }
    },
    {
      path: '/data-service/admin-download-history',
      name: 'adminDownloadHistory',
      component: () => import('@/components/pages/AdminDownloadDataHistory/AdminDownloadDataHistory.vue'),
      beforeEnter: (to, from, next) => {
        authmanage()
        next();
      }
    },
  ]
})
export const goForgotPasswordForm = () => {
  router.push('/data-service/forgot-password')
}
export const goLogin = (loginPath: string) => {
  router.push(loginPath)
}
export const goInputData = () => {
  router.push('/data-service/inputData')
}
const authentication = async () => {
  const isAuthorization = sessionStorage.getItem('isAuthorization')
  if (!isAuthorization) {
    goLogin('/data-service/login')
  }
}
const authmanage = async () => {
  authentication()
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  if (user.authority !== 0) {
    goInputData()
  }
}
export default router
