
import { defineComponent } from 'vue'
// import AppLoading from '@/components/parts/globals/Loading/AppLoading.vue'


export default defineComponent({
  name: 'App',
  // components: {
  //   'app-loading': AppLoading,
  // }
  setup(){
    // console.log(process.env.VUE_APP_API_BASE_URL)
  }
})
